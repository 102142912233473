// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
interface ImportedModule {
  name: string
  default: object
}
const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
  eager: true
})

import { createApp } from 'vue'
const app = createApp({})

for (const path in vueSingleFileComponents) {
  const importedModule = vueSingleFileComponents[String(path)]
  const name = path.split('/').pop()?.split('.')[0]
  if (name !== undefined && typeof name === 'string') {
    app.component(name, importedModule.default)
  }
}
if (document.querySelector('#vue-app')) {
  app.mount('#vue-app');
} else {
  console.info('No "#vue-app"');
}

/**
 * 🦄: Now do your magic.
 */

import Plyr from 'plyr';

import VideoPlayer from './Components/VideoPlayer.js';
import SpeakersSearch from './Components/SpeakersSearch.js';

document.addEventListener('DOMContentLoaded', () => {

  VideoPlayer(Plyr)
  SpeakersSearch()

})

let words = document.getElementsByClassName('animated-word');
let wordArray = [];
let currentWord = 0;
if (words.length > 0) {
  words[currentWord].style.opacity = 1;
  for (let i = 0; i < words.length; i++) {
    splitLetters(words[i]);
  }
}

function changeWord() {
  let cw = wordArray[currentWord];
  let nw = currentWord == words.length - 1 ? wordArray[0] : wordArray[currentWord + 1];
  for (let i = 0; i < cw.length; i++) {
    animateLetterOut(cw, i);
  }

  for (let i = 0; i < nw.length; i++) {
    nw[i].className = 'letter behind';
    nw[0].parentElement.style.opacity = 1;
    animateLetterIn(nw, i);
  }

  currentWord = (currentWord == wordArray.length - 1) ? 0 : currentWord + 1;
}

function animateLetterOut(cw, i) {
  setTimeout(function () {
    cw[i].className = 'letter out';
  }, i * 80);
}

function animateLetterIn(nw, i) {
  setTimeout(function () {
    nw[i].className = 'letter in';
  }, 340 + (i * 80));
}

function splitLetters(word) {
  let content = word.innerHTML;
  word.innerHTML = '';
  let letters = [];
  for (let i = 0; i < content.length; i++) {
    let letter = document.createElement('span');
    letter.className = 'letter';
    letter.innerHTML = content.charAt(i);
    word.appendChild(letter);
    letters.push(letter);
  }

  wordArray.push(letters);
}

if (words.length > 0) {
  changeWord();
  setInterval(changeWord, 4000);
}

const mobileMenuButton = document.querySelector('.mobile-menu-button') as HTMLElement
const backdrop = document.querySelector('.backdrop') as HTMLElement
const menuHolder = document.querySelector('.menu-holder') as HTMLElement

if (mobileMenuButton) {
  mobileMenuButton.addEventListener('click', function () {
    mobileMenuButton.classList.toggle('is-active')
    const buttonText = mobileMenuButton.firstElementChild as HTMLElement

    if (mobileMenuButton.classList.contains('is-active') && buttonText) {
      buttonText.innerHTML = 'Close'
    } else {
      buttonText.innerHTML = 'Menu'
    }

    backdrop.classList.toggle('is-active')
    menuHolder.classList.toggle('is-active')

    if (menuHolder.classList.contains('is-active')) {
      menuHolder.style.display = 'block'
      setTimeout(() => {
        menuHolder.style.opacity = '1'
      }, 100)
    } else {
      menuHolder.style.opacity = '0'
      setTimeout(() => {
        menuHolder.style.display = 'none'
      }, 300)
    }
  })
}
const termFilter = document.getElementById('term-filter');
if (termFilter) {
  termFilter.addEventListener('change', function () {
    location.href = updateQueryStringParameter(window.location.href, 'term', this.value);
  });
}

const yearFilter = document.getElementById('year-filter');
if (yearFilter) {
  yearFilter.addEventListener('change', function () {
    location.href = updateQueryStringParameter(window.location.href, 'y', this.value);
  });
}

function updateQueryStringParameter(uri: string, key: string, value: string) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

//accordions

const accordions = document.querySelectorAll("#js-accordion");

const openAccordion = (accordion) => {
  const content = accordion.querySelector(".js-accordion-content");
  accordion.classList.add('active');
  content.classList.remove('opacity-0')
  content.style.maxHeight = content.scrollHeight + "px";
};

const closeAccordion = (accordion) => {
  const content = accordion.querySelector(".js-accordion-content");
  accordion.classList.remove('active');
  content.style.maxHeight = null;
};

accordions.forEach((accordion) => {
  const intro = accordion.querySelector('.js-accordion-intro');
  const content = accordion.querySelector('.js-accordion-content');

  intro.onclick = () => {
    if (content.style.maxHeight) {
      closeAccordion(accordion);
    } else {
      accordions.forEach((accordion) => closeAccordion(accordion));
      openAccordion(accordion);
    }
  };
});
