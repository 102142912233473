<script lang="ts">
import axios from 'axios';

export default {
    props: [
      'title',
      'content',
      'isUserRedirectUrl',
      'isNotUserRedirectUrl'
    ],
    data: () => ({
        apiPath: '/wp-json/form-api/post',
        errors: [],
        contactEmail: '',
        apiMessage: '',
        apiLoading: false
    }),

    methods: {
        checkForm(e: any) {
            this.errors = [];

            // Check if email is valid
            if (!this.contactEmail) {
                this.errors.push('We need an email address to continue.');
            } else if (!this.validEmail(this.contactEmail)) {
                this.errors.push('We need a valid email address to continue.');
            }

            // Remove spaces
            // Check if email is valid
            if (this.contactEmail && this.validEmail(this.contactEmail) ) {
              this.contactEmail = this.contactEmail.replace(/\s/g, '');
              this.postForm(e);
            }

            e.preventDefault();
        },
        postForm(e: any) {
            e.preventDefault();

            this.apiLoading = true;
            axios.post(this.apiPath, {
                contactEmail: this.contactEmail
            })
            .then(
              response => {
                this.apiMessage = '';
                this.apiLoading = false

                if( response.data.is_user ) {
                  window.location.href = decodeURI(this.isUserRedirectUrl) + '?email=' +this.contactEmail;
                } else {
                  console.info('Not a member');
                  window.location.href = decodeURI(this.isNotUserRedirectUrl) + '?email=' + this.contactEmail;
                }
              }
            )
            .catch((e) => {
              console.log(e);
              this.apiMessage = 'Something went wrong. Please reload and try again.';
              this.apiLoading = false
            })
        },
        validEmail: function (email: string) {
            // eslint-disable-next-line no-useless-escape
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

    }
}

</script>
<template>

    <div class="w-full md:w-2/3 mx-auto p-8 md:p-16 my-20 bg-white space-y-4 rounded-lg">

      <h2 class="heading-ml leading-tight">{{ this.title }}</h2>

      <slot></slot>

      <label class="inline-block font-bold">Check if I’m a member:</label>
      <input
        v-model="contactEmail"
        :class="{ 'input-error': errors.length > 0 }"
        type="text"
        class="border border-gray-400 p-4 w-full rounded-lg" placeholder="Enter your email">

      <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
      <p v-text="apiMessage"></p>

      <a href="javascript:;" @click="checkForm" :disabled="apiLoading" class="button button--yellow">CHECK MY MEMBERSHIP</a>
    </div>


</template>
<style></style>
