function initVideos(Plyr) {
    if (document.querySelectorAll('[data-js="player"]').length > 0) {
      const players = Array.from(
        document.querySelectorAll('[data-js="player"]')
      ).map((p) => {
        new Plyr(p, {
          autoplay: false,
          loop: { active: true },
          controls: [
            'play-large',
            'captions',
            'play',
            'progress',
            'volume',
            'fullscreen'
          ],
          youtube: {
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            autoHide: 1
          },
          fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true
          }
        })
      })
    }
  }
  
  export default initVideos